<template>
  <dashboard-layout>
    <template #content>
      <transition
        name="slide-fade"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <router-view />
      </transition>
    </template>
  </dashboard-layout>
</template>

<script>
import { DashboardLayout } from '@/layouts';

export default {
  name: 'Organizations',

  components: {
    DashboardLayout,
  },

};
</script>

<style>

</style>
